import React from 'react'
import Link from 'gatsby-link'

import IntroSection from '../sections/intro'
import ShortSection2 from '../sections/short-section-2'
import ShortSection3 from '../sections/short-section-3'
import ShortSection4 from '../sections/short-section-4'
import IntroBgImage from '../images/backgrounds/bkg-intro.jpg'
import Section3BgImage from '../images/backgrounds/bkg-matching-is-easy.jpg'
import Layout from '../components/layout';

const IndexPage = () => (
<Layout>
	<div>
		<div className="pt0 w-100 center intro-background"
			style={{
				backgroundImage: `url(${IntroBgImage})`,
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat'
			}}
		>
			<IntroSection 
				className="w-100 w-80-m w-75-l"
			/>
		</div>
		<div className="pt0 w-100 center skewY5"
			style={{
				backgroundColor: 'white'
			}}>
			<ShortSection2/>
		</div>
		<div className="pt0 w-100 center skewY5"
			style={{
				backgroundImage: `url(${Section3BgImage})`,
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'top center'
			}}
		>
			<ShortSection3
				className="w-100 w-80-m w-75-l"
			/>
		</div>
		<div className="pt0 w-100 center bg-frendli-yellow"
		style={{
				marginTop: '-50px',
				paddingTop: '50px',
			}}>
			<ShortSection4/>
		</div>
	</div>
</Layout>
)

export default IndexPage
